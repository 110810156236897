export const environment = {
  production: true,
  gmapApiKey: 'AIzaSyAYZrzZrsahhJ4Dul9so0Gtqlc2zsYYakU',
  mapTilerApiKey: 'guije8uc7YLJDKiLJqys',
  dataApiKey: undefined,
  datadogConfig: `{
    "applicationId": "dba586b3-04e9-4c8e-be61-252b3199b45b",
    "clientToken": "pub171e42f1e2e041beea9a316723fa8556",
    "env": "staging",
    "allowedTracingUrls": [
      {"match": "https://staging.dryad.app", "propagatorTypes": ["tracecontext"]},
      {"match": "https://db.staging.dryad.app", "propagatorTypes": ["tracecontext"]},
      {"match": "https://forestfloor.staging.dryad.app", "propagatorTypes": ["tracecontext"]}
    ],
    "trackFrustrations": true
  }`,
  gitHash: '1336bcc',
  deploymentVersion: '',
  serviceRoot: 'staging.dryad.app',
  directusBaseUrl: 'https://db.staging.dryad.app',
}
