import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import {
  DateUtils,
  DeviceState, Gateway,
  SensorNode,
  UpGateway,
  UpMessage,
} from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { HttpParams } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class UplinkMessageService extends ApiHttpService {
  readonly LAST_MESSAGES_UPDATE_INTERVAL = 3000

  constructor() {
    super()
  }

  uplinkMessages(
    sensorId: string,
    time: string = "5m",
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    let cloneParams = new HttpParams()
    cloneParams = cloneParams.append("limit", 5000)
    return this.get<UpMessage[]>(
      "uplink/messages/" + sensorId + "/" + time,
      cloneParams
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  gatewayUplinkMessages(
    gatewayId: string,
    time: string = "5m",
    sort: string = "asc",
  ): Observable<UpGateway[]> {
    let cloneParams = new HttpParams()
    cloneParams = cloneParams.append("limit", 5000)
    return this.get<UpGateway[]>(
      "gateways/messages/" + gatewayId + "/time/" + time + "/now",
      cloneParams
    ).pipe(
      map((response) =>
        plainToClass(UpGateway, response).sort((a: UpGateway, b: UpGateway) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  uplinkMessagesForDate(
    sensorId: string,
    date: string,
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    return this.get<UpMessage[]>(
      "uplink/messages/" + sensorId + "/date/" + date,
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  uplinkMessagesForTimeRange(
    sensorId: string,
    startDateTime: string,
    endDateTime: string,
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    return this.get<UpMessage[]>(
      "uplink/messages/" +
      sensorId +
      "/time/" +
      startDateTime +
      "/" +
      endDateTime,
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  lastUplinkMessageForSensorNode(endDeviceId: string): Observable<UpMessage> {
    return this.get<UpMessage>(
      `uplink/messages/${endDeviceId}/5y?limit=1`
    ).pipe(map((response) => plainToClass(UpMessage, response)))
  }

  updateSensorNodesWithStatus(
    sensorNodes: SensorNode[],
  ): Observable<SensorNode[]> {
    return this.put<SensorNode[]>(
      "uplink/messages/sensorNode/last",
      {
        ids: sensorNodes
          .map((sensorNode: SensorNode) => sensorNode.ns_end_device_id)
      }
    ).pipe(
      map((response) => plainToClass(UpMessage, response)),
      map((upMsgs: UpMessage[]) =>
        this.updateSensorNodeStatus(sensorNodes, upMsgs),
      ),
    )
  }

  updateGatewaysWithStatus(
    gateways: Gateway[],
  ): Observable<Gateway[]> {
    return this.put<Gateway[]>(
      "gateways/messages/last",
      gateways
        .map((gateway: Gateway) => gateway.ns_gateway_id)
    ).pipe(
      map((response) => plainToClass(UpMessage, response)),
      map((upMsgs: UpMessage[]) =>
        this.updateGatewayStatus(gateways, upMsgs),
      ),
    )
  }

  private updateSensorNodeStatus(
    sensorNodes: SensorNode[],
    upMessages: UpMessage[],
  ): SensorNode[] {
    return sensorNodes.map((sensorNode: SensorNode) => {
      const upMessage = upMessages.find(
        (upMsg: UpMessage) => upMsg.endDevice.id === sensorNode.ns_end_device_id,
      )
      if (upMessage) {
        sensorNode.lastSignal = new Date(upMessage.time)
        sensorNode.state = DateUtils.deviceState(sensorNode.lastSignal)
      } else {
        sensorNode.lastSignal = undefined
        sensorNode.state = DeviceState.INACTIVE
      }
      return sensorNode
    })
  }

  private updateGatewayStatus(
    gateways: Gateway[],
    upMessages: UpMessage[],
  ): Gateway[] {
    return gateways.map((gateway: Gateway) => {
      const upMessage = upMessages.find(
        // @ts-ignore
        (upMsg: UpMessage) => upMsg.deviceId === gateway.ns_gateway_id,
      )
      if (upMessage) {
        gateway.lastSignal = new Date(upMessage.time)
        gateway.state = DateUtils.deviceState(gateway.lastSignal)
      } else {
        gateway.lastSignal = undefined
        gateway.state = DeviceState.INACTIVE
      }
      return gateway
    })
  }
}
