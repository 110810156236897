 <div class="user-management-container">
  <div class="users-table-container">
    <p-table
      class="users"
      [value]="allUsers"
      [paginator]="totalRecords > 10"
      [rows]="10"
      [lazy]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [pageLinks]="5"
      [sortField]="'first_name'"
      (onLazyLoad)="loadUsers($event)"
      [totalRecords]="totalRecords">
      <ng-template pTemplate="caption">
        <div class="flex flex-column search-users">
          <h2>Users List</h2>
          <div class="search p-input-icon-left">
            <input pInputText type="text"
              [(ngModel)]="searchTerm"
              (keyup)="onSearch()"
              placeholder="Search users" />
            <i class="pi pi-search"></i>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="header-tr">
          <th pSortableColumn="first_name">First Name <p-sortIcon field="first_name"></p-sortIcon></th>
          <th pSortableColumn="last_name">Last Name <p-sortIcon field="last_name"></p-sortIcon></th>
          <th>Email</th>
          <th style="width: 170px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td style="text-align: left">
            {{ user.first_name }}
          </td>
          <td style="text-align: left">
            {{ user.last_name }}
          </td>
          <td style="text-align: left">
            {{ user.email }}
          </td>
          <td class="user-controls" style="text-align: left">
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div *ngIf="isLoading" class="spinner-container">
  <dryad-logo-spinner
    class="flex-center-content margin-auto justify-content-center color-primary"
    style="width: 70%"></dryad-logo-spinner>
</div>
