import { Component, OnInit } from "@angular/core"
import { DataService, DirectusApiService } from "@dryad-web-app/shared/data-access"
import {
  BaseButton,
  Device,
  DeviceFilter,
  DeviceTypes,
  DirectusDeviceResponse,
  ObservableState,
  Site,
  SiteDeviceFilterSettings,
} from "@dryad-web-app/shared/state"
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api"
import { DeviceListService } from "../../shared/device-list.service"
import {
  DeviceDirectusApiService,
} from "../../../../../../libs/shared/data-access/src/lib/services/common-services/device-directus-api.service"

@Component({
  selector: "app-site-device",
  templateUrl: "./site-device.component.html",
  styleUrls: [ "./site-device.component.scss" ],
})
export class SiteDeviceComponent implements OnInit {
  siteDeviceFilterSettings: SiteDeviceFilterSettings
  selectedSites: Site[] = []
  userSites: number[] = []
  clickedOnDevice = false
  devices: Device[] = []
  selectedDevice: Device
  filterDevices: Device[] = []
  defaultLimit = 10
  toggle = true
  totalDevice: number
  loading = true
  buttonList: BaseButton[] = []

  tableColumns: Object[] = [
    {
      label: "Device",
      field: "name",
    },
    {
      label: "Site",
      field: "site",
    },
    {
      label: "Device type",
      field: "deviceType",
    },
  ]

  selectedDevices: Device[]
  showResetButton = false
  selectedDeviceType: Object[] = [
    {
      label: "Sensor",
      field: DeviceTypes.SENSOR_NODE,
    },
    {
      label: "Gateway",
      field: DeviceTypes.GATEWAY,
    },
  ]

  constructor(
    private dataService: DataService,
    private deviceListService: DeviceListService,
    private oss: ObservableState,
    private directusService: DirectusApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private deviceDirectusApiService: DeviceDirectusApiService,
  ) {
    this.initButtonList()
    this.dataService.getSiteData(this.selectedSites)
  }

  ngOnInit(): void {
    // this.getDevices(this.defaultLimit, 0)
    this.initSite()
    this.dataService.siteDeviceFilterSettings.subscribe(
      (settings: SiteDeviceFilterSettings) => {
        this.siteDeviceFilterSettings = settings

        if (!!this.siteDeviceFilterSettings.deviceType?.length) {
          this.filterDevices = this.devices.filter(
            (el: Device) =>
              this.siteDeviceFilterSettings.deviceType.indexOf(el.deviceType) >=
              0,
            this.siteDeviceFilterSettings.deviceType
          )
        } else this.filterDevices = this.devices
        if (!!this.siteDeviceFilterSettings.sites?.length) {
          this.filterDevices = this.filterDevices.filter(
            (el: Device) =>
              this.siteDeviceFilterSettings.sites.indexOf(el.site) >= 0
          )
        }
      }
    )
  }

  initFilterData(): void {
    this.filterDevices = this.devices
  }

  initButtonList(): void {
    this.buttonList.push(
      new BaseButton(
        "Edit",
        (selectedEntity: Device) => {
          this.editDevice(selectedEntity)
        },
        true,
        false,
        "ph-pencil-simple ph-2x",
        "p-button p-button-rounded p-button-text button-blue"
      )
    )
  }

  initSite(): void {
    this.oss.allSites$().subscribe((sites: Site[]) => {
      sites.forEach((s: Site) => {
        this.userSites.push(s.id)
      })
    })
  }

  editDevice(device: Device): void {
    if (device.deviceType === DeviceTypes.SENSOR_NODE)
      this.deviceListService.editSensorNode(device.deviceData)
    if (device.deviceType === DeviceTypes.GATEWAY)
      this.deviceListService.editGateway(device.deviceData)
  }

  deleteDevice(device: Device): void {
    if (device.deviceType === DeviceTypes.SENSOR_NODE)
      this.deviceListService.deleteSensor(device.deviceData)
    if (device.deviceType === DeviceTypes.GATEWAY)
      this.deviceListService.deleteGateway(device.deviceData)
  }

  private getDevices(limit, offset, filter?: any): void {
    if (this.selectedSites.length === 0) {
      const siteIds = []
      this.oss.allSites$().subscribe((sites: Site[]) => {
        sites.forEach((s: Site) => {
          const site = new Site(s.network_server, s.name)
          site.id = s.id
          siteIds.push(s.id)
          this.selectedSites.push(site)
        })
        const filterObj: DeviceFilter = new DeviceFilter()
        filterObj["site"] = {
          "_in": siteIds,
        }
        this.getSensor(limit, offset, filterObj)
      })
    } else {
      if (!filter) {
        const filterObj: DeviceFilter = new DeviceFilter()
        filterObj["site"] = {
          "_in": this.selectedSites.map(d => d.id),
        }
        filter = filterObj
      }
      this.getSensor(limit, offset, filter)
    }
  }


  getSensor(limit, offset, filter): void {
    const listDevices = []
    this.deviceDirectusApiService.sensorList(limit, offset, filter).subscribe((nodes: DirectusDeviceResponse) => {
      this.totalDevice = nodes.meta.filter_count
      nodes.data.forEach((node) => {
        // @ts-ignore
        listDevices.push(new Device(node.name, node.site.name, DeviceTypes.SENSOR_NODE, node))
      })
      this.filterDevices = listDevices
      this.loading = false
      this.getGateways(limit, offset, filter)
    })
  }

  getGateways(limit, offset, filter): void {
    const listDevices = []
    this.deviceDirectusApiService.gatewayList(limit, offset, filter).subscribe((gateways: DirectusDeviceResponse) => {
      this.totalDevice += gateways.meta.filter_count
      if (this.filterDevices.length < this.defaultLimit) {
        gateways.data.forEach((gateway) => {
          // @ts-ignore
          listDevices.push(new Device(gateway.name, gateway.site.name, DeviceTypes.GATEWAY, gateway))
        })
        this.filterDevices = [ ...this.filterDevices, ...listDevices ]
      }
    })
  }

  filterDevicesList(filter: LazyLoadEvent): void {
    this.getDevices(this.defaultLimit, filter.first, this.createFilter(filter))
  }

  changedSelections(): void {
    this.showResetButton = this.selectedDevices.length > 0
  }

  resetDevices(): void {
    const gateways = []
    const sensors = []
    this.selectedDevices.forEach(device => {
      if (device.deviceType === DeviceTypes.GATEWAY) gateways.push(device.deviceData.id)
      else sensors.push(device.deviceData.id)
    })
    this.confirmationService.confirm({
      header: "Reset confirmation ",
      message: "This will remove the location data and reset the deployment status to pending. " +
        "This action cannot be undone. Do you want to proceed?",
      icon: "pi pi-info-circle",
      acceptLabel: "Confirm",
      rejectButtonStyleClass: " cancel-btn button-only-boarder",
      acceptButtonStyleClass: "p-button ",
      accept: () => this.sendResetRequest(gateways, sensors),
    })
  }

  sendResetRequest(gateways: number[], sensors: number[]): void {
    this.directusService.resetDeviceStatus(gateways, DeviceTypes.GATEWAY).subscribe(() => {
      this.directusService.resetDeviceStatus(sensors, DeviceTypes.SENSOR_NODE).subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: "Device Update",
        })
      })
    })
  }

  clickOnDevice(device: Device): void {
    this.clickedOnDevice = true
    this.selectedDevice = device
  }


  createFilter(filter: LazyLoadEvent): any {
    const filterObject = {
      _or: [],
      _and: [{
        "site": {
          "_in": this.userSites,
        },
      }],
    }
    if (filter.filters) {
      if (filter.filters.name?.value) {
        const namFiler = new DeviceFilter()
        namFiler["name"] = {
          "_contains": filter.filters.name?.value,
        }
        filterObject._or.push(namFiler)
      }
      if (filter.filters.site?.value) {
        const siteFilter = new DeviceFilter()
        const siteIds = filter.filters.site.value.map(f => f.id)
        siteFilter["site"] = {
          "_in": siteIds,
        }
        filterObject._or.push(siteFilter)
      }
    }
    return filterObject._or.length > 0 ? filterObject : null
  }
}
