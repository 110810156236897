<ng-template #userCreationWrapper>
  <div class="user-creation-wrapper">
    <p-card header="{{ actionType === 'ADMIN_USER_CREATE' ? 'Create User' : 'Edit User'}}">
      <form [formGroup]="userForm"  (ngSubmit)="handleSubmit()" class="user-create-form">
        <h4>Contact Information</h4>
        <div class="user-form flex flex-column">
          <label for="first-name" class="mb-1"
          >First Name *</label>
          <input
            id="first-name"
            type="text"
            pInputText
            formControlName="firstName"
            placeholder="Add First Name"
            required
            class="mb-2"
          />
          <span *ngIf="userForm.get('firstName').hasError('whitespace')"
          class="label-error-note mb-2">The first name cannot be empty.</span>
          <label for="first-name" class="mb-1"
          >Last Name *</label>
          <input
            id="last-name"
            type="text"
            pInputText
            formControlName="lastName"
            placeholder="Add Last Name"
            required
            class="mb-2"
          />
          <span *ngIf="userForm.get('lastName').hasError('whitespace')"
          class="label-error-note mb-2">The last name cannot be empty.</span>
          <label for="email-address" class="mb-1"
          >Email address *</label>
          <input
            id="email-address"
            type="email"
            pInputText
            formControlName="emailAddress"
            placeholder="Add Email Address"
            required
            class="mb-2"
          />
          <span *ngIf="userForm.get('emailAddress').hasError('whitespace')"
          class="label-error-note mb-2">The email address cannot be empty.</span>
        <div class="flex flex-row align-items-start justify-content-between mb-2 mt-5"
        >
          <h4>Organization</h4>
          <div class="flex flex-row align-items-center" *ngIf="!isLoggedInUserEndCustomer">
            <p-checkbox
            binary="true"
            formControlName="newOrganizationChecked"
          ></p-checkbox>
          <span class="ml-2">Create new Organization</span>
          </div>
        </div>
        <div
        class="flex flex-row flex-column mb-4"
        *ngIf="!userForm.get('newOrganizationChecked').value">
          <label class="mb-1">Organization *</label>
          <p-dropdown
          id="org-selection"
            [options]="organizations"
            formControlName="selectedOrganization"
            optionLabel="name"
            placeholder="Select Organization"
            dataKey="uuid"
            [filter]="true"
            filterBy="name"
          ></p-dropdown>
        </div>
        <div *ngIf="userForm.get('newOrganizationChecked').value" class="flex flex-row flex-column mb-4">
          <label class="mb-1" for="new-org-name">New Organization *</label>
          <input
            id="new-org-name"
            type="text"
            pInputText
            formControlName="newOrgName"
          />
          <span *ngIf="!showNameExistsAlready && !userForm.get('newOrgName').hasError('whitespace')" class="mt-1">The new organization will be added to the database.</span>
          <span *ngIf="showNameExistsAlready" class="label-error-note mt-2">This organization name exists already.</span>
          <span *ngIf="userForm.get('newOrgName').hasError('whitespace')"
          class="label-error-note mt-2">The new organization name cannot be empty.</span>
        </div>

        <label class="mb-1">Permission level *</label>
        <p-dropdown
          [options]="userRoles"
          formControlName="selectedPermission"
          optionLabel="name"
          placeholder="Select Permission level"
        ></p-dropdown>

        <div class="fire-watch-role-container mt-4">
          <p-checkbox
          binary="true"
          formControlName="fireWatchRole"
          label="Receive fire alert emails"></p-checkbox>
          <p class="fire-watch-info">By default, the new user will receive fire alert email notifications.
            Uncheck this box to disable these notifications.</p>
        </div>
        <div
        class="flex flex-row align-items-center justify-content-end user-create-buttons-container"
      >
        <button
          type="button"
          class="create-user-form-button cancel-button"
          (click)="handleCancel()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="create-user-form-button create-button"
          [class.button-disabled]="userForm.invalid || isSubmitting || userForm.pristine"
          [disabled]="!userForm.valid || isSubmitting || userForm.pristine"
        >
         Submit
        </button>
      </div>
        </div>
      </form>
    </p-card>
</div>
</ng-template>

<div *ngIf="isLoading; else userCreationWrapper" class="spinner-container">
  <dryad-logo-spinner
    class="flex-center-content margin-auto justify-content-center color-primary"
    style="width: 70%"></dryad-logo-spinner>
</div>
