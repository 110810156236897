import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { serviceRootUrl } from "apps/silvanet-web/src/app/utils/api-utils"
import { Observable } from "rxjs"

export interface PaginatedOrganizationList {
  count: number
  next?: string
  previous?: string
  results: Organization[]
}
export interface Organization {
  readonly uuid?: string
  readonly url?: string
  readonly urn?: string
  readonly created_at?: Date
  readonly updated_at?: Date
  readonly client_id: number
  parent?: string
  name: string
}

export interface OrganizationCreationPayload {
  name: string
}

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Injectable({
  providedIn: "root",
})
export class OrganizationsService {
  private readonly forestFloorBaseURLOrganizations = `${forestFloorBaseUrl}/api/organizations/`

  constructor(
    private http: HttpClient,
  ){}

  async getOrganizationsPage(page?: number, page_size?: number): Promise<PaginatedOrganizationList> {
    let params = new HttpParams()
    if (page !== undefined && page !== null)
      params = params.set("page", <any>page)

    if(page_size !== undefined && page_size !== null)
      params = params.set("page_size", page_size)

    return await this.http.get<PaginatedOrganizationList>(this.forestFloorBaseURLOrganizations, { params: params }).toPromise()
  }

  async getAllOrganizations(): Promise<Organization[]> {
    const firstPage = await this.getOrganizationsPage(1, undefined)
    if (!firstPage.next) return firstPage.results
    const totalPageCount = Math.ceil(firstPage.count / firstPage.results.length)
    const pageIndices = Array.from({ length: totalPageCount - 1 }, (_, i) => i + 2)
    const remainingPages = await Promise.all(pageIndices.map((pageNum) => this.getOrganizationsPage(pageNum, undefined)))
    return [firstPage, ...remainingPages].flatMap(pageObj => pageObj.results)
  }

  // Engine Compatible endpoint to ease transition for the web app
  createNewOrganization(name: string): Observable<Organization> {
    const requestBody = {
      name: name,
    }
    return this.http.post<Organization>(`${this.forestFloorBaseURLOrganizations}engine_create/`, requestBody)
  }
}
