import { Component, OnInit, ViewChild } from "@angular/core"
import {
  Gateway,
  ObservableState,
  SensorNode,
  SignalStatus,
} from "@dryad-web-app/shared/state"
import { DirectusApiService, GatewayService, SensorNodeService } from "@dryad-web-app/shared/data-access"
import _ from "lodash"
import { filter, take } from "rxjs/operators"
import { MapComponent } from "../map/map.component"
import { startOnboarding } from "./map-page.onboarding"

@Component({
  selector: "app-map",
  templateUrl: "./map-page.component.html",
  styleUrls: [ "./map-page.component.scss" ],
})
export class MapPageComponent implements OnInit {
  allGateways: Gateway[]
  allSensors: SensorNode[]
  _sensorNodeRecord: Record<number, SensorNode> = {}

  @ViewChild("map") mapComponent: MapComponent

  constructor(
    private oss: ObservableState,
    private gatewayService: GatewayService,
    private sensorNodeService: SensorNodeService,
    private directusService: DirectusApiService,
  ) { }

  async ngOnInit(): Promise<void> {
    const [sites, gateways, sensors] = await Promise.all([
      this.oss.allSites$().pipe(filter(s => !!s.length), take(1)).toPromise(),
      this.gatewayService.gateways().toPromise(),
      this.directusService.getMapSensorNodes().toPromise(),
    ])
    const siteIds = new Set(sites.map(site => site.id))
    const activeSiteIds = new Set<number>()
    this.allGateways = gateways.filter(gw => siteIds.has(gw.site))
    this.sensorNodeRecord = _.fromPairs(sensors
      .filter(sn => siteIds.has(sn.site as number))
      .map(sensor => {
        activeSiteIds.add(sensor.site as number)
        return [sensor.id, {
          ...sensor,
          signal_status: SignalStatus.OFFLINE,
          latitude: Number(sensor.latitude),
          longitude: Number(sensor.longitude),
        }]
      }))

    sites.filter(site => activeSiteIds.has(site.id)).forEach(site => {
      const siteId = site.id
      this.sensorNodeService.sensorNodesWithStatus(siteId).subscribe(sensorNodes => sensorNodes.forEach(sensorNode => {
        this.sensorNodeRecord = { ...this._sensorNodeRecord, [sensorNode.id]: sensorNode }
      }))
    })
  }

  set sensorNodeRecord(value: Record<number, SensorNode>) {
    this._sensorNodeRecord = value
    this.allSensors = Object.values(value)
  }

  onMapReady(): void {
    startOnboarding(this.mapComponent)
  }
}
