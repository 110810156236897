<mgl-image
  *ngFor="let icon of markerIcons | keyvalue"
  [id]="icon.key"
  [data]="icon.value"
  >
</mgl-image>

<mgl-geojson-source
  id="devicesSource"
  [data]="{ type: 'FeatureCollection', features: devices }"
></mgl-geojson-source>

<mgl-layer
  id="devices-focus"
  source="devicesSource"
  type="symbol"
  [filter]="['==', ['get', 'id'], ['literal', focus?.properties.id || -1]]"
  [layout]="{
    'icon-image': 'selection',
    'icon-overlap': 'always',
    'text-overlap': 'always',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': [0, -20]
  }"
></mgl-layer>

<mgl-layer
  id="devices-statuses"
  source="devicesSource"
  type="symbol"
  [filter]="['!=', ['get', 'signal_status'], 'fire alert']"
  [layout]="{
    'icon-image': ['get', 'iconName'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-overlap': 'always',
    'icon-anchor': 'bottom',
    'icon-size': 0.5,
    'symbol-z-order': 'viewport-y'
  }"
></mgl-layer>

<mgl-layer
  id="devices-alerts"
  source="devicesSource"
  type="symbol"
  [filter]="['==', ['get', 'signal_status'], 'fire alert']"
  [layout]="{
    'icon-image': ['get', 'iconName'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-overlap': 'always',
    'icon-anchor': 'bottom',
    'icon-size': 0.5,
    'symbol-z-order': 'viewport-y'
  }"
></mgl-layer>

<mgl-layer
  id="device-badges"
  source="devicesSource"
  type="symbol"
  [minzoom]="16"
  [filter]="['any', ['get', 'showAlert'], ['get', 'showWarning']]"
  [layout]="{
    'icon-image': ['case', ['get', 'showAlert'], 'alert-badge', 'warning-badge'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-overlap': 'always',
    'icon-anchor': 'bottom',
    'icon-size': 0.33,
    'symbol-z-order': 'viewport-y',
    'icon-offset': [30, -75]
  }"
></mgl-layer>
<mgl-layer
  id="devices-text"
  source="devicesSource"
  type="symbol"
  [minzoom]="16"
  [layout]="{
    'text-field': '{name}',
    'text-font': ['Arial', 'sans-serif'],
    'text-size': 14,
    'text-allow-overlap': false,
    'symbol-sort-key': ['get', 'sortKey'],
    'symbol-z-order': 'viewport-y'
  }"
  [paint]="{
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 1,
    'text-translate': [0, 15]
  }"
></mgl-layer>
