import { Component, OnInit } from "@angular/core"
import { DryadRoutes, FLOATING_PANEL_ACTIVATE, FloatingPanel,
  ObservableState,
  User } from "@dryad-web-app/shared/state"
import { Router } from "@angular/router"
import { LazyLoadEvent } from "primeng/api"
import { RbacService } from "@dryad-web-app/shared/data-access"
import { Subject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { UserFF, UsersService } from "../../service/http/users.service"

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {

  allUsers: UserFF[] = []
  isLoading: boolean
  totalRecords: number
  searchTerm: string
  private searchSubject = new Subject<string>()

  constructor(
    private oss: ObservableState,
    private router: Router,
    private usersService: UsersService,
    private rbacService: RbacService,
  ) {
  }

  ngOnInit(): void {
    if (!this.rbacService.isStandardUser()) this.createAddUserFloatingPanel()
    this.searchSubject.pipe(debounceTime(300)).subscribe(() => {
      this.loadUsers({ first: 0, rows: 10, sortField: "first_name", sortOrder: 1 })
    })
  }

  async loadUsers(event: LazyLoadEvent): Promise<void> {
    this.isLoading = true
    const pageNumber = (event.first / event.rows) + 1 || 1
    const pageSize = event.rows || 10
    // default sort by first_name
    const sortField = event.sortField || "first_name"
    const sortOrder = event.sortOrder || -1
    const sortParam = sortField && (sortOrder === 1 ? sortField : `-${sortField}`)
    const usersResponse = await this.usersService.getAllUsers(pageNumber, pageSize, [sortParam], this.searchTerm).toPromise()
    this.allUsers = usersResponse.results
    this.totalRecords = usersResponse.count
    this.isLoading = false
  }

  addUser(): void {
    this.router.navigate([DryadRoutes.USER_CREATE])
  }

  editUser(user: User): void {
    this.router.navigate([DryadRoutes.USER_MANAGEMENT + "/" + user.id])
  }

  createAddUserFloatingPanel(): void {
    const floatingPanel = new FloatingPanel("Add User", () => this.addUser(), "button")
    this.oss.dispatch({
      type: FLOATING_PANEL_ACTIVATE,
      payload: floatingPanel,
    })
  }

  onSearch(): void {
    this.searchSubject.next(this.searchTerm.trim())
  }
}
