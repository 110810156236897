import { Injectable } from "@angular/core"
import {
  NetworkServerService,
  SensorNodeService,
} from "@dryad-web-app/shared/data-access"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { ActivationMode } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class FormFactoryService {

  constructor(
    private networkServerService: NetworkServerService,
    private sensorNodeService: SensorNodeService,
  ) {
  }

  sensorNodeFormFields(): FormlyFieldConfig[] {
    return [
      {
        key: "data",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Data" },
        fieldGroup: [
          {
            key: "name",
            type: "primeng-input",
            templateOptions: {
              label: "Name",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "sensor_node_type",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Type",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.sensorNodeService.types(),
              valueProp: "id",
              labelProp: "label",
            },
          },
          {
            key: "active",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Active",
              description: "",
              required: false,
            },
          },
          {
            key: "latitude",
            type: "primeng-input",
            templateOptions: {
              label: "Latitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "longitude",
            type: "primeng-input",
            templateOptions: {
              label: "Longitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "notes",
            type: "primeng-text-area",
            templateOptions: {
              label: "Notes",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
        ],
      },
      {
        key: "details",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Details" },
        fieldGroup: [
          {
            key: "activationMode",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Activation Mode",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.sensorNodeService.activationModes(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "idPrefix",
            type: "primeng-input",
            templateOptions: {
              label: "Device ID Prefix",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "devAddr",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "Device Address",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "frameNetworkSIntKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "Network Session Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "appSKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "App Session Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "appKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "App Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "nwkKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Network Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "joinEUI",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Join EUI",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "networkServerKekLabel",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Network Server Kek Label",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "applicationServerKekLabel",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Application Network Server Kek Label",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "applicationServerId",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Application Server Id",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "pingSlotPeriodicity",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.MULTICAST,
            templateOptions: {
              label: "Ping Slot Periodicity",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "devEUI",
            type: "primeng-input",
            templateOptions: {
              label: "Device EUI",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "lorawanVersion",
            type: "primeng-dropdown",
            templateOptions: {
              label: "LoRaWAN MAC Version",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.networkServerService.macVersions(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "lorawanPhyVersion",
            type: "primeng-dropdown",
            templateOptions: {
              label: "LoRaWAN PHY Version",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.networkServerService.phyVersions(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "frequencyPlanId",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Frequency Plan",
              placeholder: "",
              description: "Description",
              required: true,
              options: [],
              filter: true,
              valueProp: "id",
              labelProp: "name",
            },
          },
          {
            key: "macSupports32BitFCnt",
            type: "primeng-checkbox",
            templateOptions: {
              label: "MAC Support 32 Bit Frame Count",
              description: "",
              required: false,
            },
          },
          {
            key: "supportsClassC",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Supports Class C",
              description: "",
              required: false,
            },
          },
          {
            key: "supportsClassB",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Supports Class B",
              description: "",
              required: false,
            },
          },
          {
            key: "resetsFrameCnt",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Resets Frame Count",
              description: "",
              required: false,
            },
          },
          {
            key: "skipPayloadCrypto",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Skip Payload Crypto",
              description: "",
              required: false,
            },
          },
        ],
      },
    ]
  }

  sensorNodeMetaFields(): FormlyFieldConfig[] {
    return [
      {
        key: "name",
        type: "primeng-input",
        templateOptions: {
          label: "Name ",
          disabled: true
        },
        wrappers: ["primeng-table"],
      },
      {
        key: "notes",
        type: "primeng-text-area",
        templateOptions: {
          label: "Notes ",
        },
        wrappers: ["primeng-table"],
      },
    ]
  }

  sensorNodeLocationFields(): FormlyFieldConfig[] {
    return [
      {
        key: "latitude",
        type: "primeng-input",
        templateOptions: {
          label: "Latitude ",
           pattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/

        },
        wrappers: ["primeng-group"],
      },
      {
        key: "longitude",
        type: "primeng-input",
        templateOptions: {
          label: "Longitude ",
           pattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/

        },
        wrappers: ["primeng-group"],
      },
    ]
  }

  getGatewaySettingsField(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "frequency_min",
            type: "primeng-input",
            templateOptions: {
              label: "Min frequency",
              placeholder: "",
              description: "Min frequency",
              required: true,
            },
          },
          {
            key: "frequency_max",
            type: "primeng-input",
            templateOptions: {
              label: "Max frequency",
              placeholder: "",
              description: "Max frequency",
              required: true,
            },
          },
          {
            key: "hw_version",
            type: "primeng-input",
            templateOptions: {
              label: "Hardware version",
              placeholder: "",
              description: "Hardware version",
              required: true,
            },
          },
        ],
      },
    ]
  }

}
