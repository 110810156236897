export enum DialogMode {
  CREATE = 0,
  VIEW = 1,
  UPDATE = 2,
  DELETE = 3,
}

export enum AxisTypes {
  CATEGORY = "category",
  VALUE = "value",
  TIME = "time",
}

export enum SeriesTypes {
  LINE = "line",
}

export enum DeviceTypes {
  SENSOR_NODE = "SensorNode",
  GATEWAY = "Gateway",
}

export enum Tabs {
  ACTIVE = 0,
  INACTIVE = 1,
  CALIBRATING = 2,
  ONFIRE = 3
}

export enum EntityTypes {
  SENSOR_NODE = 1,
  SENSOR = 2,
  GATEWAY = 3,
  SITE = 4
}

export enum MenuOrientation {
  STATIC,
  OVERLAY,
}

export enum DeviceDeploymentStatus {
  PENDING = 0,
  SAVED = 1,
  DEPLOYED = 2,
  FAILED = 3,
  TEST_STARTED = 4,
  TESTED = 5,
  TEST_FAILED = 6
}

export enum DeviceDeploymentStatusLabels {
  PENDING = "Pending",
  DEPLOYED = "Deployed"
}

export enum DeviceDeploymentStatusTag {
  PENDING = 15,
  DEPLOYED = 16
}

export enum FormEvent {
  UPDATE = "update",
  DESTROY = "destroy",
  CLICK_SCANNER = "click_scanner"
}

export enum GatewayTypes {
  MESH_GATEWAY = 1,
  BORDER_GATEWAY = 2,
}

export enum GateWayTypeLabel {
  MESH_GATEWAY = "Mesh Gateway",
  BORDER_GATEWAY = "Border Gateway",
}

export enum AlertPhases {
  PHASE_ONE = 1,
  PHASE_TWO = 2
}

export enum ChartAxis {
  X = "x",
  Y = "y"
}

export enum TimeFormats {
  H24 = "HH:mm:ss",
  H12 = "h:mm:ss a"
}

export enum BatteryLevel {
  MAX_BATTERY_LEVEL = 120,
  MIN_BATTERY_LEVEL = 40,
  MIN_BATTERY_LEVEL_GATEWAY_ESB = 400,
  MAX_BATTERY_LEVEL_GATEWAY_ESB = 1580,
  MIN_BATTERY_LEVEL_GATEWAY_STM = 3.8,
  MAX_BATTERY_LEVEL_GATEWAY_STM = 13.5,
  MIN_BATTERY_LEVEL_GATEWAY_GEN_3 = 3.8,
  MAX_BATTERY_LEVEL_GATEWAY_GEN_3 = 21.4,
}

export enum DownLinkSExecutionTypes {
  DOWNLINK_CMD = "downlink_cmd",
  ML_FUOTA = "ml_fuota",
  SN_FUOTA = "sn_fuota",
  MG_FUOTA = "mg_fuota",
  BG_FUOTA = "bg_fuota"
}

export enum JobStatus {
  IN_PROGRESS = "In-Progress",
  COMPLETED = "Completed",
  FAILED = "Failed"
}

export enum GateSettingActions {
  BULK_UPDATE = "bulk_update",
  DOWNLOAD_SETTINGS = 'download_settings'
}

export enum FileTypes {
  ML = "ml",
  SN = "sn",
  BG = "bg",
  MG = "mg",
  SITE = "site",
  GENERIC = "generic"
}

export enum JobTypes {
  FUOTA = "fuota",
  FILE_TRANSFER = "fileTransfer",
  REFRESH_TOPOLOGY = "sensorRegistration",
  FILE_DOWNLOAD = "fileDownload",
  FILE_WAVE = "fileWave",
  RANGE_TEST = "rangeValidation",
  FALL_BACK = "gatewayFallback",
  GATEWAY_FUOTA = "gatewayFuota",
  FILE_DISTRIBUTION = "fileDistribution"
}
export enum FileWaveStatus {
  START = "start",
  STOP = "stop",
  STATUS = "status",
}

export enum SignalStatus {
  ACTIVE = "active",
  PENDING = "pending",
  OFFLINE = "inactive",
  FIRE_ALERT = "fire alert"
}

export enum EnergyStatus {
  NORMAL = "normal",
  ALERT = "alert",
  WARNING = "warning"
}

export enum GatewayNetworkType {
  UNKNOWN = "Unknown / TBD",
  CELLULAR = "Cellular",
  SATELLITE = 'Satellite'
}

export enum GatewayPowerType {
  UNKNOWN = "Unknown / TBD",
  SOLAR = "Solar",
  POE = "POE",
}