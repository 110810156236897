import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import { datadogRum } from "@datadog/browser-rum"
import { setBasePath } from "@shoelace-style/shoelace"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

setBasePath("/assets/shoelace/")

if (environment.production) enableProdMode()

if (!!environment.datadogConfig) {
  try {
    const datadogConfig = JSON.parse(environment.datadogConfig)
    datadogRum.init({
      site: "datadoghq.eu",
      service: "dryad-web-app",
      env: "dev",
      version: environment.deploymentVersion || environment.gitHash,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      telemetrySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      ...datadogConfig,
    })

    datadogRum.startSessionReplayRecording()
  }catch (e) {
    console.log("Error loading Datadog config")
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
