<div *ngIf="currentAlert">
  <div class="alert-dialog-container">
      <p-dialog [modal]="true"
      [draggable]="false" [closable]="true"
      [(visible)]="currentAlert.show_modal"
       [style]="{ width: '38.5rem' }">
       <ng-template pTemplate="header" >
        <div class="alert-dialog-header flex flex-row align-items-center">
          <img src="../../../assets/images/sensor-node-marker-fire.svg" width="30" height="30" class="mr-2">
          <sl-tooltip content="{{currentAlert.alert_site.name}}">
            <h3 class="mr-3">Fire detected in {{currentAlert.alert_site.name}}</h3>
          </sl-tooltip>
          <ng-container *ngIf="alertsCounter > 1">
            <sl-badge variant="danger" pill>+ {{ alertsCounter -1 }} more</sl-badge>
          </ng-container>
        </div>
       </ng-template>
       <div>
        <div class="flex flex-row justify-content-between mb-4">
         <div class="flex flex-row align-items-center">
          <span class="material-symbols-outlined">
            pin_drop
            </span>
            <span>
              Affected Site
            </span>
         </div>
         <span class="text-bold">{{currentAlert.alert_site.name}}</span>
        </div>
        <div class="flex flex-row justify-content-between mb-4">
         <div class="flex flex-row align-items-center">
          <span class="material-symbols-outlined">
            schedule
            </span>
            <span>Time</span>
         </div>
           <div>
              <span class="text-bold">{{currentAlert.alert_events[0].created_at | date:'HH:mm'}} </span>
           </div>
        </div>
        <div class="flex flex-row justify-content-between mb-4">
         <div class="flex flex-row align-items-center">
          <span class="material-symbols-outlined">
            event
            </span>
            <span>Date</span>
         </div>
           <div>
              <span class="text-bold">{{currentAlert.alert_events[0].created_at | date: 'dd.MM.yyyy'}} </span>
           </div>
        </div>
        <div class="flex flex-row justify-content-between mb-4">
         <div class="flex flex-row align-items-center">
          <img src="../../../assets/images/sn-skeleton.svg">
          <span class="ml-3">
            Sensor
          </span>
         </div>
            <span class="text-bold">{{currentAlert.alert_sensor?.name}}</span>
        </div>
        <div class="flex flex-row justify-content-between align-items-start mb-4">
         <div class="flex flex-row align-items-center">
          <span class="material-symbols-outlined">
            my_location
            </span>
            <span>
              Sensor Location
            </span>
         </div>
            <div *ngIf="currentAlert.alert_sensor?.latitude && currentAlert.alert_sensor?.longitude; else noCoordinates" class="flex flex-column">
              <div class="flex flex-row justify-content-end">
                  <a href="https://www.google.com/maps/search/?api=1&query={{currentAlert.alert_sensor.latitude}},{{currentAlert.alert_sensor.longitude}}"
                  target=”_blank” class="flex align-items-center">
                  <span class="material-symbols-outlined open-in-new">
                      open_in_new
                   </span>
                  <span class="underline cursor-pointer alert-link text-bold">
                      Show on Google Maps
                  </span>
                  </a>
              </div>
              <span class="alert-sensor-coordinates">(Lat: {{currentAlert.alert_sensor?.latitude}}, Long: {{currentAlert.alert_sensor?.longitude}})</span>
            </div>
        </div>
       </div>
        <ng-template pTemplate="footer" class="flex justify-content-end gap-2">
          <p-divider></p-divider>
           <div class="buttons-container">
            <button class="alert-buttons acknowledge-button" (click)="acknowledgeAlert(false)">{{alertsCounter > 1 ? 'Mute Alerts' : 'Mute Alert'}}
              <span class="material-icons pl-1">
              notifications_off
              </span></button>
            <button class="alert-buttons details-button" (click)="acknowledgeAlert(true)">
              {{alertsCounter > 1 ? 'Go to Alert Center' : 'See Alert Details'}}
            </button>
           </div>
        </ng-template>
    </p-dialog>
    </div>
</div>

<ng-template #noCoordinates>
<span class="alert-sensor-coordinates">No registered coordinates.</span>
</ng-template>

<div *ngIf="alertsLoaded && activeAlerts.length > 0">
<audio autoplay hidden>
  <source src="assets/audio/alarm.m4a" type="audio/mpeg">
  Your browser does not support the audio element.
</audio>
</div>