// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, OnDestroy } from "@angular/core"
import { Router } from "@angular/router"
import { SensorDetails } from "@dryad-web-app/shared/state"

import { Subscription, from } from "rxjs"
import { mergeMap, map } from "rxjs/operators"
import { DeviceDirectusApiService } from "../../../../../shared/data-access/src/lib/services/common-services/device-directus-api.service"
import { AlertsService } from "apps/silvanet-web/src/app/service/http/alerts.service"
import { EnrichedAlert } from "apps/silvanet-web/src/app/alerts/types"

@Component({
  selector: "app-alert-modal",
  templateUrl: "./alert-modal.component.html",
  styleUrls: ["./alert-modal.component.scss"],
})

export class AlertModalComponent implements OnInit, OnDestroy {

  loggedInUserSuperAdmin = false
  alertsLoaded = false
  activeAlerts: EnrichedAlert[] = []
  currentAlert: EnrichedAlert
  acknowledgedAlertsIds: string[] = []
  displayModal = true
  alertsCounter = 0

  private subscription: Subscription = new Subscription()

  constructor(
    private router: Router,
    private alertsService: AlertsService,
    private directusDeviceService: DeviceDirectusApiService,
  ) { }

  ngOnInit(): void {
    this.subscription.add([this.alertsService.activeAlerts$
      .pipe(
        mergeMap((alerts: EnrichedAlert[]) => {
          return from(alerts).pipe(
            mergeMap((alert: EnrichedAlert) => {
              // Get the `device_id` from the first alert event
              const firstAlertEventSNId = alert.alert_events?.[0]?.payload?.end_device_ids?.device_id
              return this.directusDeviceService.getSensorByNsEndDeviceId(firstAlertEventSNId).pipe(
                map((sensor: SensorDetails) => ({
                  ...alert,
                  alert_sensor: sensor.data[0],
                  show_modal: true,
                }))
              )
            })
          )
        })
      )
      .subscribe((alertData) => {
        if (!localStorage.getItem("acknowledgedAlertsIds")) {
          localStorage.setItem("acknowledgedAlertsIds", JSON.stringify([]))
        }

        const acknowledgedIds: string[] = localStorage.getItem("acknowledgedAlertsIds")?.split(",") || []
        this.activeAlerts.push(alertData)

        if (acknowledgedIds.length === 0) {
          this.currentAlert = this.activeAlerts[0]
        } else {
          // Filter alerts to check if they are acknowledged
          const notAcknowledgedAlerts = this.activeAlerts.filter((alert) => !acknowledgedIds.includes(alert.alert.uuid))

          // Set the current alert if there are existing acknowledged alerts
          this.currentAlert = notAcknowledgedAlerts.length > 0 ? notAcknowledgedAlerts[0] : undefined
        }
      }),],
       this.subscription.add(this.alertsService.activeAlerts$.subscribe(alerts => this.alertsCounter = alerts.length))
    )
  }

  acknowledgeAlert(withRouting: boolean): void {
    if (this.activeAlerts.length === 1) {
      this.acknowledgedAlertsIds.push(this.currentAlert.alert.uuid)
    }

    else this.activeAlerts.map(al => this.acknowledgedAlertsIds.push(al.alert.uuid))
    localStorage.setItem("acknowledgedAlertsIds", this.acknowledgedAlertsIds)
    this.currentAlert.show_modal = false

    if (withRouting) {
      this.alertsCounter === 1 ? this.router.navigate(['alert-center/', this.currentAlert.alert.uuid]) : this.router.navigate(['alert-center'])
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}