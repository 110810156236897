import { HttpParams } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import {
  SensorNodeService,
  TypeService,
  UplinkMessageService,
} from "@dryad-web-app/shared/data-access"
import {
  ExperimentDataRecord,
  ObservableState,
  SensorNode,
  Site,
  UpMessage,
} from "@dryad-web-app/shared/state"
import { FormlyFieldConfig } from "@ngx-formly/core"
import * as _ from "lodash"
import { MessageService } from "primeng/api"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { Subscription } from "rxjs"
import { ExperimentDataRecordService } from "../../service/http/experiment-data-record.service"

@Component({
  selector: "app-model-validation",
  templateUrl: "./model-validation.component.html",
  styleUrls: ["./model-validation.component.scss"],
})
export class ModelValidationComponent implements OnInit, OnDestroy {
  validationRecordDate: Date = new Date()
  maxDate = new Date()
  dataType = "training"
  sites: Site[] = []
  selectedSite: Site

  sensorNodes: SensorNode[] = []
  selectedSensorNode: SensorNode

  upMessages: UpMessage[] = []

  model: any = {}

  data: any[][] = [[], [], [], [], [], [], [], [], [], []]

  experimentDataFormFields: FormlyFieldConfig[] = []
  experimentDataForm: UntypedFormGroup = new UntypedFormGroup({})

  loadingChartData = false

  public chartOpt: any

  public chartMessage = "No chart data loaded"

  private subscription = new Subscription()

  private tagTypeFilter = {
    type: {
      type_id: {
        _eq: "validation_record",
      },
    },
  }

  private specimenClassFilter = {
    type: {
      type_id: {
        _eq: "validation_record_specimen_class",
      },
    },
  }

  constructor(
    private oss: ObservableState,
    private sensorNodeService: SensorNodeService,
    private experimentDataRecordsService: ExperimentDataRecordService,
    private uplinkMessageService: UplinkMessageService,
    private messageService: MessageService,
    private typeService: TypeService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    if (this.dynamicDialogConfig?.data?.type)
      this.dataType = this.dynamicDialogConfig.data.type
  }

  ngOnInit(): void {
    // this.setChartOptions()
    this.subscription.add(
      this.oss.allSites$().subscribe((sites: Site[]) => {
        this.sites = sites
      })
    )
    this.experimentDataFormFields = this.modelValidationFormFields()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  setChartOptions = (gsMax: number): void => {
    this.chartOpt = {
      mode: "real-time",
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [
          "gas-scan-0",
          "gas-scan-1",
          "gas-scan-2",
          "gas-scan-3",
          "gas-scan-4",
          "gas-scan-5",
          "gas-scan-6",
          "gas-scan-7",
          "gas-scan-8",
          "gas-scan-9",
        ],
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        name: "gas resistance (log)",
        boundaryGap: [0, "100%"],
        max: Math.round(gsMax + 1),
        min: 0,
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          type: "line",
          data: this.data[0],
          name: "gas-scan-0",
        },
        {
          type: "line",
          data: this.data[1],
          name: "gas-scan-1",
        },
        {
          type: "line",
          data: this.data[2],
          name: "gas-scan-2",
        },
        {
          type: "line",
          data: this.data[3],
          name: "gas-scan-3",
        },
        {
          type: "line",
          data: this.data[4],
          name: "gas-scan-4",
        },
        {
          type: "line",
          data: this.data[5],
          name: "gas-scan-5",
        },
        {
          type: "line",
          data: this.data[6],
          name: "gas-scan-6",
        },
        {
          type: "line",
          data: this.data[7],
          name: "gas-scan-7",
        },
        {
          type: "line",
          data: this.data[8],
          name: "gas-scan-8",
        },
        {
          type: "line",
          data: this.data[9],
          name: "gas-scan-9",
        },
      ],
    }
  }

  onSelectedSiteChange(event: any): void {
    this.selectedSensorNode = undefined
    this.subscription.add(
      this.sensorNodeService
        .sensorNodesForSiteWithTag(event.value.id, "ml")
        .subscribe((sensorNodes: SensorNode[]) => {
          this.sensorNodes = sensorNodes
        })
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectedSensorNodeChange(event: any): void {
    this.updateChartData()
    this.experimentDataForm.controls.sensor_node.setValue(
      this.selectedSensorNode.id
    )
  }

  updateChartData(): void {
    if (this.selectedSensorNode) {
      this.experimentDataForm.reset()
      this.chartMessage = ""
      this.loadingChartData = false
      const validationRecordDateStr = this.formatValiationDate()
      console.log(validationRecordDateStr)
      this.data = [[], [], [], [], [], [], [], [], [], []]
      this.subscription.add(
        this.uplinkMessageService
          .uplinkMessagesForDate(
            this.selectedSensorNode.ns_end_device_id,
            validationRecordDateStr
          )
          .subscribe(
            (upMessages: UpMessage[]) => {
              upMessages.map((upMessage: UpMessage) => {
                if (!this.isGasResistanceValueMissing(upMessage.sensorValues)) {
                  this.data[0].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-0"]),
                  ])
                  this.data[1].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-1"]),
                  ])
                  this.data[2].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-2"]),
                  ])
                  this.data[3].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-3"]),
                  ])
                  this.data[4].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-4"]),
                  ])
                  this.data[5].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-5"]),
                  ])
                  this.data[6].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-6"]),
                  ])
                  this.data[7].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-7"]),
                  ])
                  this.data[8].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-8"]),
                  ])
                  this.data[9].push([
                    Date.parse(upMessage.time),
                    Math.log(upMessage.sensorValues["gas-scan-9"]),
                  ])
                }
              })

              const maxArray = [
                Math.max(...this.data[0].map((gs: any[]) => gs[1])),
                Math.max(...this.data[1].map((gs: any[]) => gs[1])),
                Math.max(...this.data[2].map((gs: any[]) => gs[1])),
                Math.max(...this.data[3].map((gs: any[]) => gs[1])),
                Math.max(...this.data[4].map((gs: any[]) => gs[1])),
                Math.max(...this.data[5].map((gs: any[]) => gs[1])),
                Math.max(...this.data[6].map((gs: any[]) => gs[1])),
                Math.max(...this.data[7].map((gs: any[]) => gs[1])),
                Math.max(...this.data[8].map((gs: any[]) => gs[1])),
                Math.max(...this.data[9].map((gs: any[]) => gs[1])),
              ]

              this.setChartOptions(Math.max(...maxArray))

              this.experimentDataForm.controls.start_time.setValue(
                new Date(this.data[0][0][0])
              )
              this.experimentDataForm.controls.end_time.setValue(
                new Date(this.data[0][this.data[0].length - 1][0])
              )
              if (upMessages.length > 0)
                this.chartMessage = "Chart data for sensor node not found"
            },
            () => {
              this.messageService.add({
                severity: "error",
                summary: "Error loading data",
              })
              this.chartMessage =
                "Error loading chart data for chosen sensor node"
            },
            () => (this.loadingChartData = false)
          )
      )
    }
  }

  handleValidationRecordDateSelect(): void {
    this.updateChartData()
  }

  onChartEvent(event: any): void {
    // console.log(new Date(event.startValue).toISOString(), new Date(event.endValue).toISOString())
    this.experimentDataForm.controls.start_time.setValue(
      new Date(event.startValue)
    )
    this.experimentDataForm.controls.end_time.setValue(new Date(event.endValue))
  }

  isGasResistanceValueMissing(sensorValues: any): boolean {
    for (const [key, value] of Object.entries(sensorValues))
      if (key.match(/gas-scan-[0-9]/g) && value <= 0) return true
    return false
  }

  modelValidationFormFields(): FormlyFieldConfig[] {
    return [
      {
        key: "sensor_node",
        type: "primeng-input",
        templateOptions: {
          label: "Sensor Node Id",
          placeholder: "",
          description: "Sensor Node Id",
          required: true,
          disabled: true,
        },
      },
      {
        key: "start_time",
        type: "primeng-calendar",
        templateOptions: {
          label: "Start Time",
          placeholder: "",
          description: "Start Time",
          required: true,
          disabled: true,
          showTime: true,
        },
      },
      {
        key: "end_time",
        type: "primeng-calendar",
        templateOptions: {
          label: "End Time",
          placeholder: "",
          description: "End Time",
          required: true,
          disabled: true,
          showTime: true,
        },
      },
      {
        key: "log_file",
        type: "primeng-input",
        templateOptions: {
          label: "Log File",
          placeholder: "",
          description: "Log File",
          required: true,
        },
      },
      {
        key: "specimen",
        type: "primeng-input",
        templateOptions: {
          label: "Specimen",
          placeholder: "",
          description: "Specimen",
          required: true,
        },
      },
      {
        key: "specimen_class",
        type: "primeng-dropdown",
        templateOptions: {
          label: "Specimen Class",
          placeholder: "",
          description: "Specimen Class",
          options: this.typeService.types(
            "tag_label",
            new HttpParams().append(
              "filter",
              JSON.stringify(this.specimenClassFilter)
            )
          ),
          valueProp: "id",
          labelProp: "label",
        },
      },
      {
        key: "tag_labels",
        type: "primeng-dropdown",
        templateOptions: {
          label: "Training Record Tag",
          placeholder: "",
          description: "Tags",
          options: this.typeService.types(
            "tag_label",
            new HttpParams().append(
              "filter",
              JSON.stringify(this.tagTypeFilter)
            )
          ),
          valueProp: "id",
          labelProp: "label",
        },
      },
    ]
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSubmit(event): void {
    const tagLabels = {
      tag_labels: [
        { tag_label_id: this.model.tag_labels },
        { tag_label_id: this.model.specimen_class },
      ],
    }
    const mergedModel = _.clone(this.model)
    delete mergedModel.specimen_class
    this.subscription.add(
      this.experimentDataRecordsService
        .create(_.merge(mergedModel, tagLabels), this.dataType)
        .subscribe((edr: ExperimentDataRecord) => {
          this.messageService.add({
            severity: "success",
            summary: "Training Data Record Created",
            detail: JSON.stringify(edr),
          })
          this.updateChartData()
        })
    )
  }

  handleCancel(): void {
    this.ref.close()
  }

  private formatValiationDate(): string {
    return (
      this.validationRecordDate.getFullYear() +
      "-" +
      (this.validationRecordDate.getMonth() >= 9 ? "" : "0") +
      (this.validationRecordDate.getMonth() + 1) +
      "-" +
      (this.validationRecordDate.getDate() >= 10 ? "" : "0") +
      this.validationRecordDate.getDate()
    )
  }
}
