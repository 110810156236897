import {
  BatteryLevel,
  DeviceTypes,
  EntityTypes,
  GatewayTypes,
  GateWayTypeLabel, JobField,
  JobTypes,
} from "@dryad-web-app/shared/state"
import * as moment from "moment"
import { DatePipe } from "@angular/common"
import { JobStatusPipe } from "../../../../../libs/ui/dryad-ui/src/lib/pipes/job-status.pipe"

// TODO move to shared lib
function getDateString(date: Date): string {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  )
}

function formatDateString(data: string, format: string): string {
  return moment(data).format(format)
}

function unitFormat(value: string, symbol: string): string {
  return value + " " + symbol
}

function deg2rad(deg: any): number {
  return deg * (Math.PI / 180)
}

function getDistanceFromLatLonInKm(lat1: any, lng1: any, lat2: any, lng2: any): number {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  // Distance in km
  return R * c
}

function formatDistance(distance: number): string {
  return distance < 1000
    ? (distance).toFixed(2) + "m"
    : distance.toFixed(2) + "Km"
}

function getSensorType(serialId: string): EntityTypes | undefined {
  const splitSerialId = serialId.split("-")
  return splitSerialId[0] === "sn" ? EntityTypes.SENSOR_NODE : undefined
}

function getGatewayType(serialId: string): GatewayTypes | undefined {
  const splitSerialId = serialId.split("-")
  switch (splitSerialId[0]) {
    case "mg":
      return GatewayTypes.MESH_GATEWAY
    case "bg":
      return GatewayTypes.BORDER_GATEWAY
    default: return undefined
  }
}

function getDeviceTypeLabel(device: any): DeviceTypes | GateWayTypeLabel | undefined {
  let deviceType = device.entity_type
  if (deviceType instanceof Object)
    deviceType = deviceType["id"]

  if (deviceType === EntityTypes.SENSOR_NODE) return DeviceTypes.SENSOR_NODE


  if (deviceType === EntityTypes.GATEWAY) {
    let gatewayType = device.gateway_type

    if (gatewayType instanceof Object) gatewayType = gatewayType["id"]

    if (gatewayType === GatewayTypes.MESH_GATEWAY)
      return GateWayTypeLabel.MESH_GATEWAY
    else return GateWayTypeLabel.BORDER_GATEWAY
  }
  return undefined
}

function generaUUID(): string {
  let dt = new Date().getTime()
  return "xxx-xxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

function formatAirPressure(value: number): string {
  return Math.ceil(value / 100).toString()
}

function toTimeStamp(time: string): any {
  return new Date(time).getTime()
}

function calculatorSensorBattery(battery: number): number {
  if (battery > BatteryLevel.MIN_BATTERY_LEVEL) {
    const max = BatteryLevel.MAX_BATTERY_LEVEL - BatteryLevel.MIN_BATTERY_LEVEL
    const percentage = ((battery - BatteryLevel.MIN_BATTERY_LEVEL) / max) * 100
    return Number(percentage.toFixed(2))
  }
  return 0
}

function calculatorGatewayBattery(battery: number, hwVersion: string): number {
  const hwVersionAsFloat = parseFloat(hwVersion)
  const isSTM = hwVersionAsFloat >= 2.1 && hwVersionAsFloat < 3.0
  const isGen3 = hwVersionAsFloat >= 3.0 && hwVersionAsFloat < 4.0
  const [min, max] = (isSTM ? [BatteryLevel.MIN_BATTERY_LEVEL_GATEWAY_STM, BatteryLevel.MAX_BATTERY_LEVEL_GATEWAY_STM]
    : isGen3 ? [BatteryLevel.MIN_BATTERY_LEVEL_GATEWAY_GEN_3, BatteryLevel.MAX_BATTERY_LEVEL_GATEWAY_GEN_3]
      : [BatteryLevel.MIN_BATTERY_LEVEL_GATEWAY_ESB, BatteryLevel.MAX_BATTERY_LEVEL_GATEWAY_ESB])
  if (battery > min) {
    const span = max - min
    let percentage = ((battery - min) / span) * 100
    if (battery > max) percentage = 100
    return Number(percentage.toFixed(2))
  } else return 0
}

function calculateBatteryPercentage(battery: number, deviceType: DeviceTypes = DeviceTypes.SENSOR_NODE,
  hwVersion?: string): number {

  return deviceType === DeviceTypes.SENSOR_NODE ? calculatorSensorBattery(battery) : calculatorGatewayBattery(battery, hwVersion as string)
}

function createBatch(entities: any[], size = 10): any[] {
  const batches: any = []
  let offset = 0
  while (offset < entities.length) {
    batches.push(
      entities.slice(offset, offset + size)
    )
    offset += size
  }
  return batches
}

function getJobTypeLabel(jobType: JobTypes): string {
  let jobTypeLabel: string
  switch (jobType) {
    case JobTypes.FILE_TRANSFER:
      jobTypeLabel = "File Transfer"
      break
    case JobTypes.FUOTA:
      jobTypeLabel = "Sensor FUOTA"
      break
    case JobTypes.FILE_WAVE:
      jobTypeLabel = "File Wave"
      break
    case JobTypes.GATEWAY_FUOTA:
      jobTypeLabel = "Gateway FUOTA"
      break
    default:
      jobTypeLabel = ""
  }
  return jobTypeLabel
}

function getFileTransferJobFields(): JobField[] {
  return [
    new JobField("File Name", "fileName"),
    new JobField("Version", "version"),
    new JobField("Gateway EUI", "gatewayEui"),
    new JobField("Site Id", "siteId"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}

function getSNFUOTAJobFields(): JobField[] {
  return [
    new JobField("Version", "version"),
    new JobField("Site Id", "siteId"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}
function getGWFUOTAJobFields(): JobField[] {
  return [
    new JobField("Version", "version"),
    new JobField("Gateway EUI", "euiList"),
    new JobField("Site Id", "siteId"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}


function getDefaultJobFields(): JobField[] {
  return [
    new JobField("Version", "versionWithLabel.label"),
    new JobField("Gateway EUI", "gatewayEui"),
    new JobField("Type", "jobType"),
    new JobField("Site Id", "siteId"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}

function getSensorRegistrationJobField(): JobField[] {
  return [
    new JobField("Site Id", "siteId"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}
function getFileDistributionJobField(): JobField[] {
  return [
    new JobField("Type", "type"),
    new JobField("Version", "version"),
    new JobField("File Id", "fileId"),
    new JobField("Site Id", "siteId"),
    new JobField("Gateway EUI", "gatewayEui"),
    new JobField("Status", "root",JobStatusPipe),
    new JobField("Started", "createdAt", DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
    new JobField("Last Updated", "updatedAt",DatePipe, ["yyyy-MM-dd HH:mm:ss"]),
  ]
}

function getJobFields(jobType: string): JobField[] {
  let jobFields: JobField[]
  switch (jobType) {
    case JobTypes.FILE_TRANSFER:
      jobFields = this.getFileTransferJobFields()
      break
    case JobTypes.FUOTA:
      jobFields = this.getFUOTAJobFields()
      break
    case JobTypes.REFRESH_TOPOLOGY:
      jobFields = this.getSensorRegistrationJobField()
      break
    case JobTypes.FILE_WAVE:
      jobFields = this.getFileDistributionJobField()
      break
    default:
      jobFields = this.getDefaultJobFields()
  }
  return jobFields
}

export const utility = {
  getDateString: getDateString,
  formatDateString: formatDateString,
  unitFormat: unitFormat,
  getDistanceFromLatLonInKm: getDistanceFromLatLonInKm,
  formatDistance: formatDistance,
  getSensorType: getSensorType,
  getGatewayType: getGatewayType,
  getDeviceTypeLabel: getDeviceTypeLabel,
  generaUUID: generaUUID,
  formatAirPressure: formatAirPressure,
  toTimeStamp: toTimeStamp,
  calculateBatteryPercentage: calculateBatteryPercentage,
  createBatch: createBatch,
  getJobTypeLabel: getJobTypeLabel,
  getFileTransferJobFields: getFileTransferJobFields,
  getSNFUOTAJobFields: getSNFUOTAJobFields,
  getGWFUOTAJobFields: getGWFUOTAJobFields,
  getDefaultJobFields: getDefaultJobFields,
  getJobFields: getJobFields,
  getSensorRegistrationJobField: getSensorRegistrationJobField,
  getFileDistributionJobField: getFileDistributionJobField,
}
